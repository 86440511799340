/** To use the google fonts
font-family: 'Montserrat', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Open Sans', sans-serif;
*/
.aid-container {

}
.aid-video {
  float: right;
  margin-left: 1em;
  border: solid #aaa 1px;
}


@include mk-format((
  cname:head, 
  szrange:10, 
  align:center,
  font: 'Montserrat',
  //boxshadow: true,
  textshadow: true,
  //background: rgba(255,200,200,.4),
  //border: #faa,
  margin: .5em,
  padding: .5em,
  ));

  @include mk-format((
    cname: left,
    szrange: 8,
    color: #300,
    font: 'Open Sans',
  ));

  .pk-head-wrapper {
    //@include mk-wrapper;
    //@include v-padding(1em);
    //@include v-margin(1em);
  }

.pk-site-head {
  background-color: #200;
  color: white;
  font-size: x-large;
  padding: .5em;
  text-transform: uppercase;
  text-align: center;
}


body .nav-tabs li.nav-item.pk-tab-item {
  min-width: 10%;
  a.nav-link {
    font-size: large;
  }
}
/*
font-family: 'Montserrat', sans-serif;
*/

.sub-head-wrapper {
  @include mk-wrapper ((
    border: false,
    boxshadow: false,
    align: center,

  ));
}
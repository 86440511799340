//CSS / Styling that when used together create effects, like tooltips, liking someone, etc
/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */

// For us with JQuery Dialog Automation
.js-dialog-content {
  display: none;
}

// An element with class drop-hover has display: none, unless
// the user hovers over a containing element with class 'drop-in'
// or an immediately preceding sibling element with class 'drop-after'

.drop-hover {
  display: none;
  position: absolute;
  top: 3em;
  width: 130%;
  left: -30%;
  padding: 2px;
  border-radius: 6px;
  opacity: 1;
  background-color: white;
}

.drop-in {
  position: relative;
  background-color: white;
  opacity: 1;
  z-index: 10;
}
.drop-after:hover + .drop-hover,
.drop-in:hover .drop-hover {
  display: block;
  background-color: white;
  opacity: 1;
}

// Speach bubble effects
div.speech-bubble{
   margin-left: 2em;;
   margin-right: 2em;;
   padding: 1em;
   color: white;
   display: inline-block;
   width: 90%; 
   min-height: 6em; 
   border-radius: 1em;
}
.bubble-content {
  width: 100%;
  float: left;
}
div.speech-bubble.left {
   text-align: left;
   background: #006;
}
div.speech-bubble.right {
   background: #400;
}
// Not browser independent yet - FF & Chrome differ, also w. themselves on refresh
//div.speech-bubble::before,
//div.speech-bubble::after {
//   content:"";
//   position: absolute;
//   width: 0;
//   height: 0;
//   border-top: 1.5em solid transparent;
//   border-bottom: 1.5em solid transparent;
//   margin: 0;
//}
//div.speech-bubble.left::before {
//   border-right: 2.5em solid blue;
//   margin-left: -3.5em;

//}
//div.speech-bubble.right:after {
//   border-left: 2.5em solid red;
//   margin-left: .5em;
// //  margin-left: 410px;
// //  left: 70%;
//   top: 1.8em;
//}








// Some Bootstrap Mods to make even height columns
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.rowclass-equalheight {
    display: table;
}

.colclass-equalheight {
    float: none;
    display: table-cell;
    vertical-align: top;
}

// Management of "Favoriting" & Blocking, in combo with JS / AJAX

.social-control-container {
  height: 4em;
  text-align: center;
}

div.blocked,
div.unblocked,
div.favorited,
div.unfavorited {
  display: inline-block;
  width: 2em;
  height: 2em;
  background-size: contain;
}
div.favorited,
div.unfavorited {
  transform: translateY(-.5em);
  margin: auto;
}
//// Good to here!
div.unfavorited {
  background-image: url('../img/Full-Empty-Star.svg');
}
div.favorited {
  background-image: url('../img/Full-Yellow-Star.svg');
}


div.favorite-toggle.favorited:hover,
div.favorite-toggle.unfavorited:hover {
  background-image: url('../img/Full-Half-Star.svg');
  cursor: pointer;
}

div.favorite-toggle.favorited::after,
div.favorite-toggle.unfavorited::after {
  top: 2em;
  transform: translateX(-50%);
  display: block;
  margin-top: -.3em;
  position: relative;
  white-space: pre;
}
div.favorite-toggle.unfavorited::after {
  content: "Not a favorite..";
  color: #884;
}

div.favorite-toggle.favorited::after {
  color: #962;
  content: 'A Favorite!';
}

div.favorite-toggle.favorited:hover::after {
  color: #888;
  content: 'Unfavorite?';
}

div.favorite-toggle.unfavorited:hover::after {
  color: #962;
  content: "Favorite them!";
}



// Similar with Favoriting, but for blocking 


div.blocked,
div.unblocked {
  border-radius: .5em;
  background-color: green;
  line-height:  2em;
}

div.blocked {
  background-color: red;
}

div.unblocked:hover {
  cursor: pointer;
  background-color: #800;
}
div.blocked:hover {
  cursor: pointer;
  background-color: #080;
}

div.unblocked::before,
div.blocked::before {
  font-size: x-large;
  font-weight: bold;
}
div.unblocked::before {
  content: '\2713';
}
div.blocked::before {
  content: '\2718';
}

div.blocked::after,
div.unblocked:after {
  font-size: small;
  margin-top: -.3em;
  white-space: pre;
  position: relative;
  display: block;
  transform: translateX(-60%);
}

div.blocked::after {
  color: #600;
  content: 'Blocked';
  font-style: italic;
}

div.unblocked::after {
  content: 'Unblocked';
  color: #686;
}

div.unblocked:hover::after {
  color: red;
  content: 'Block User?';
}

div.blocked:hover::after {
  color: green;
  content: 'Unblock User?';
}


// Some button styling, also for divs, and menus?
$default-pkmvc-button: 1 !default;
@if $default-pkmvc-button == 1 {
  body .pkmvc-button:focus,
  body .pkmvc-button:visited,
  body .pkmvc-button {
    font: bold 1em/1 "helvetica neue", helvetica, arial, sans-serif;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to top, #759ae9, #376fe0);
    border-top: 1px solid #1f58cc;
    border-right: 1px solid #1b4db3;
    border-bottom: 1px solid #174299;
    border-left: 1px solid #1b4db3;
    border-radius: .3em;
    box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
    color: #fff;
    padding: .5em ;
    margin: .5em;
    text-shadow: 0 -1px 1px #1a5ad9;
  }
  body .pkmvc-button:focus a,
  body .pkmvc-button:visited a,
  body .pkmvc-button a {
    color: #fff;
    text-decoration: none;
  }

  body .pkmvc-button:hover,
  body .pkmvc-button:hover a {
      text-decoration: none;
      color: #ffe;
  }
  body .pkmvc-button:hover {
      background-image: linear-gradient(to top, #557ac9, #174fc0);
      cursor: pointer; 
  }

  body .pkmvc-button:active {
      border-top: 1px solid #1b4db3;
      border-right: 1px solid #174299;
      border-bottom: 1px solid #133780;
      border-left: 1px solid #174299;
      box-shadow: inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
  }
}

body .light-button:focus,
body .light-button:visited,
body .light-button {
    font:  1em/1 "helvetica neue", helvetica, arial, sans-serif;
    display: inline-block;
    height: 1.5em;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to top, #759aa9, #376fa0);
    border-top: 1px solid #1f58cc;
    border-right: 1px solid #1b4db3;
    border-bottom: 1px solid #174299;
    border-left: 1px solid #1b4db3;
    border-radius: .2em;
    box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
    color: #fff;
    font-weight: normal;
    padding: .3em ;
    margin: .3em;
    text-shadow: 0 -1px 1px #1a5ad9;
}
  body .light-button:focus a,
  body .light-button:visited a,
  body .light-button a {
    color: #fff;
    text-decoration: none;
  }

  body .light-button:hover,
  body .light-button:hover a {
      text-decoration: none;
      color: #ffe;
  }
  body .light-button:hover {
      background-image: linear-gradient(to top, #557ac9, #174fc0);
      cursor: pointer; 
  }

  body .light-button:active {
      border-top: 1px solid #1b4db3;
      border-right: 1px solid #174299;
      border-bottom: 1px solid #133780;
      border-left: 1px solid #174299;
      box-shadow: inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
  }


// Set of behaviors for inline login
$inline-login-color: #ccf !default;
.inline-login a{
  color: $inline-login-color;
}
$inline-login-hover-color: #aaf !default;
.inline-login a:hover{
  text-decoration: none;
  color: $inline-login-hover-color;
}

form.inline-login input.form-control.checkbox-inline {
  display: inline-block;
  width: auto;
  margin-left: .25em;
  vertical-align: middle;
}

$inline-login-margin-top: 0 !default;
//$inline-login-zoom: .9 !default;
.inline-login {
  //display: inline-block;
  margin-top: $inline-login-margin-top;
 // zoom: $inline-login-zoom;
}

label.inline-remember {
  color: white;
  display: inline-block;
  margin-left: .5em;
}
$inline-login-form-button-hpad: .4rem !default;
$inline-login-form-button-vpad: .3rem !default;
form.inline-login.form-inline button.btn-primary {
  margin-left: .5em;
  padding:  $inline-login-form-button-vpad $inline-login-form-button-hpad;
}

form.inline-login.form-inline button.btn-primary {
  @include below-bp(sm) {
    margin-left: 0;
  }
}

$inline-form-font-size: .8em !default; 
form.inline-login.form-inline {
  font-size: $pk-top-menu-font-size;
  .form-control{
    //font-size: $inline-form-font-size;
    font-size: $pk-top-menu-font-size;
    padding: .25em .5em;
  }
}

// Don't remember exactly what key frames were for - some kind of animation - cool
@keyframes pulse {
  0% {
    border: solid red 2px;
    background-color: rgba(100,100,255,.8);
  }
  50% {
    border: solid blue 2px;
    background-color: rgba(250,200,100,.8);
  }
  100% {
    border: solid red 2px;
    background-color: rgba(100,100,255,.8);
  }
}
img.big-picture-dialog {
  max-width: 70rem;
  max-height: 70rem;
  //border-radius: 2rem;
  //border: solid blue 5px;

} 
// Basic uniform styling for disabled controls - both the native HTML inputs,
 // which bootstrap applies a style to through the pseudo-selector :disabled -
 // but that doesn't work with my compound inputs like "multiselect" Components
 // that want this styling should have the class 'form-control', and have the
 // class 'disabled'
 ///

//div.multiselect.disabled,
//input[disabled].disabled {
.form-control.disabled {
  background-color: #444;
  color: #888;
}

.form-control.multiselect {
  text-align: left;
}


@media (max-width: 600px) {
  div.speech-bubble{
    width: auto;
  }
  div.speech-bubble:before, 
  div.speech-bubble:after {
    content: none;
  } 
}

// So far, for automatic delete row buttons
.pk-compact {
  margin: 0;
}

// Try Vertical/Horizontal styling for Radio/YesNo set 

.rs-horizontal .rs-layout {
  display: inline-block;
//  margin-left: .5rem;
//  margin-right: .5rem;
}

.rs-yn .radio.rs-layout {
  width: 4rem
}
.rs-horizontal .rs-layout.rs-yn label {
  //margin-left: 1rem;
  //margin-right: 1rem;
  //min-width: 4rem;

}
// To center jQuery UI dialogs - pk-big-picture, but generalizable
// Need to add "data-dialogClass='pk-big-picture'" to the js-dialog-button 

.ui-dialog.ui-widget.pk-big-picture {
  @include absolutecenter;
}

.pimg-del, .del-cross, .del-btn {
  background-image: url('/mixed/img/cross-31176.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 4px;
  right: 4px;
}

.pimg-del:hover, .del-cross:hover, .del-btn:hover {
  cursor: pointer; 
}

.ajax-loader {
  background-image: url('/mixed/img/ajax-loader.gif');
  background-repeat: no-repeat;
  background-position: center; 
}

.actionable {
  cursor: pointer; 
  &:hover {
    font-weight: bold;
  }
}


.jq-error-dialog .ui-dialog-titlebar {
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: large;
}



///////////////  Classes for changing state by JS - checkboxes & triangles

.box, .triangle {
  display: inline;
  cursor: pointer;
}

// Three checkboxes states
.box.all-selected {
  content: "\2611";
  font-size: 1.2em;
}
.box.none-selected {
  content: "\25A2";
  font-size: 0.85em;
}
.box.some-selected {
  content: "\25A3";
}

//Triangles depending if tree branch is open....


ul.triangle-tree li div.box.some-selected::before {
  content: "\25A3";
  padding-right: 2px;
}
ul.triangle-tree li div.box.all-selected::before {
  content: "\2611";
  font-size: 1.2em;
}
ul.triangle-tree li div.box.none-selected::before {
  content: "\2610";
  font-size: 1.4em;
}

ul.triangle-tree li div.open:before,
ul.triangle-tree li.open:before {
  content: "\25BE";
  font-size: 1.7em;
}
ul.triangle-tree li div.closed:before,
ul.triangle-tree li.closed:before {
  content: "\25B8";
  font-size: 1.7em;
}
ul.triangle-tree li div.leaf:before,
ul.triangle-tree li.leaf:before {
  content: '\2008';
  padding-right: 0.75em;
}



/*****   To show resumes in an iframe & overlay a link */
// I don't know how to size an embed

.upload-icon-label.embed {
  position: relative;
  top: -40px;
}

.upload-input.embed {
  position: relative;
  top: -30px;
}

$embed-height: 300px !default;
$embed-width: 200px !default;
a.embed-cover {
  position: absolute;
  margin: 0;
  padding: 0;
  height: $embed-height - 50px;
  width: $embed-width;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: transparent;
}
.embed-wrapper {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: $embed-height;
  width: $embed-width;
  z-index: 100;
}
iframe.doc,
embed.doc {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
/*
*/

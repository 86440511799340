/** SCSS For Image Gallery Display, using Bootstrap 4 **/
@import 'variables';
@import 'mixins';
@import 'pk-mixins';
@import 'pk-utility';
@import 'pk-effects';

.gallery-image-wrapper {
  margin: .5em;
  padding: .5em;
  //border: solid red 1px;
  text-align: center;
  background-color: #efe;
  /*
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  */
}

.image-desc {

//  background-color: #eef;
}
.img-img-wrapper {
 // background-color: #faf;
}

img.resp-gal-img {
 // border: solid blue 1px;
}

.outer-margin-5 {
  margin: .5em;
}


.resp-gal-img {
}
.inner-pad-5 {
  padding: .5em;
}
.tpm-center-img {
  position: relative;
}
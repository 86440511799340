@import "variables";
@import "mixins";
@import "pk-mixins";
@import 'pk-utility';

.pk-resume {
  font-family: verdana;
  font-size: 120%;
  padding: 2em;
}

.pk-resume h1 {
  font-size: x-large;
}


.pk-resume h2 {
  font-size: large;
}

.res-head {
  text-align: center;
}
.split-line {
  @include clearfix;
}

.rsection-lead {
  @include clearfix;

}

/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */
/*
  Generic utility SCSS partial for PK Extensions
    Created on : Oct 11, 2015, 4:57:29 AM
    Author     : Paul Kirkaas
*/
//@import 'pk-variables';
$grid-breakpoints:(xs: 0, sm: 544px, md: 768px, lg: 992px, xl: 1200px) !default;
$grid-columns:               12 !default;
$grid-gutter-width:          1.875rem !default; // 30px
$inner-border-color: #888 !default;
$inner-border-spread: 1px !default;


//Makes a border INSIDE the block - so takes no space
@mixin inner-border {
  box-shadow: inset 0px 0px 0px $inner-border-spread $inner-border-color;
}

.inner-border {
  @include inner-border;
}


// Depends on jQuery removing this class when loaded!
.hide-until-loaded {
  display: none !important;
}


.overflowauto, .overflow-auto {overflow:auto;}

.top-square-corners { border-top-left-radius: 0px ! important; border-top-right-radius: 0px ! important; }
.top-round-corners { border-top-left-radius: 5px ! important; border-top-right-radius: 5px ! important; }
.bottom-round-corners { border-bottom-left-radius: 5px ! important; border-bottom-right-radius: 5px ! important; }
.bottom-square-corners { border-bottom-left-radius: 0px ! important; border-bottom-right-radius: 0px ! important; }

.max-width-100 {max-width: 100% !important;}
.width-auto, .auto-width {width: auto !important}

.tal, .text-align-left, .align-left, .left-align { text-align: left ! important; }
.tar, .text-align-right, .align-right, .right-align { text-align: right ! important; }
.tac, .text-align-center, .align-center, .center-align { text-align: center ! important; }
.italic { font-style: italic; }
.bold { font-weight: bold ! important; }

.float-left, .floatleft, .fleft, .left { float: left ! important; }
.float-right, .floatright, .fright, .right { float: right ! important; }

.block { display: block ! important; }
.inline { display: inline-block ! important; }
.hidden { display: none; }
.invisible { visibility: hidden; }


.marginauto, .margin-auto, .auto-margin, .automargin {
  margin-left: auto ! important;
  margin-right: auto ! important;
  margin-top: auto ! important;
  margin-bottom: auto ! important;
}

.min-line-height:empty::after {
    content: ".";
    visibility: hidden;
}

.self-center {
  justify-content: center;
}

@include mk-spacing;

.hw1, .wh1 {height: 1rem !important; width: 1rem !important;}
.hw2, .wh2 {height: 2rem !important; width: 2rem !important;}

.border-light, .border, .mildborder, .mild-border 
    { border: solid $default-border-color 1px; }
.border-rad {
  @extend .border;
  border-radius: 5px;
}

.border-red {border-color: #f00 ! important;}

.border5, .border-5 { border-width: 5px ! important; border-style: solid;}

.border-radius-5, .border-radius5,  .b-r-5, .br5 {border-radius: 5px ! important}

.fullheight, div .full-height { height: 100% ! important; }
.fullwidth, div .full-width { width: 100% ! important; }

.fullheight-1 {height: calc(100% - 1em ) ! important;}
.fullheight-2 {height: calc(100% - 2em ) ! important;}
.fullheight-3 {height: calc(100% - 3em ) ! important;}
.fullheight-4 {height: calc(100% - 4em ) ! important;}
.fullheight-5 {height: calc(100% - 5em ) ! important;}
.fullheight-6 {height: calc(100% - 6em ) ! important;}


.fullwidth-1 {width: calc(100% - 1em ) ! important;}
.fullwidth-2 {width: calc(100% - 2em ) ! important;}
.fullwidth-3 {width: calc(100% - 3em ) ! important;}
.fullwidth-4 {width: calc(100% - 4em ) ! important;}
.fullwidth-5 {width: calc(100% - 5em ) ! important;}
.fullwidth-6 {width: calc(100% - 6em ) ! important;}

.height-90pc, .height90pc, .most-height, .mostheight { height: 90% !important; }
.height-80pc, .height80pc { height: 80% !important; }
.height-70pc, .height70pc { height: 70% !important; }
.height-60pc, .height60pc { height: 60% !important; }
.height-50pc, .height50pc, .halfheight { height: 60% !important; }

.width-90pc, .width90pc, .most-width, .mostwidth { width: 90% !important; }
.width-80pc, .width80pc { width: 80% !important; }
.w-a {width: auto !important; }

/** !!! MY GOD - WHY DIDN'T I KNOW ABOUT THIS BEFORE!!!!  '*/
.hfc {
  height: fit-content;
}

.wfc {
  width: fit-content;
}

.spacing-5 { margin: 5px; padding: 5px; border-radius: 5px; }

.oh {overflow: hidden;}

.big-box-shadow {
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
 }

.box-shadow {
  box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
}



.box-shadow-2 {
  box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
}



/* The CONTAINING element must be RELATIVE*/
.horizontal-center { @include make-horizontal-center }
.h-center { @include make-horizontal-center }
.vertical-center { @include make-vertical-center }
.v-center { @include make-vertical-center }


/*The block to center in the containing block should have this class. The
containing block needs to be positioned relatively*/
.center {
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%,-50%);

}

/* Centers a block within an enclosing positioned block*/
/* Not sure the enclosing block needs this class, but keep in case */
/* Also seems to work with display: inline-flex */
/* This doesn't even seem to require that the contained centered box be positioned*/
.fcenter, center-flex {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-center-flex, .vfcenter {
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.align-flex-items-start {
  align-items: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-evenly {
  display: flex; 
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.h-flex { //Just to be consistent
  display: flex;
}
.v-flex {
  display: flex;
  flex-direction: column;
}
.f-center, .flex-center {
  display: flex;
  justify-content: center;
}

.fi-fixed {
  flex-grow: 0;
  flex-shrink: 0;
}

.fi-resize {
  flex-grow: 1;
  flex-shrink: 1;
}

.fg,.fg1 { flex-grow: 1; }
.fg2 { flex-grow: 2; }
.fg3 { flex-grow: 3; }
.fg4 { flex-grow: 4; }
.fg5 { flex-grow: 5; }
.fs, .fs1 { flex-shrink: 1; }

.nfg,.fg0 { flex-grow: 0; }
.nfs, .fs0 { flex-shrink: 0; }

.fw {flex-wrap:wrap;}

.flex-rowwrap {
  display:flex;
  flex-wrap: wrap;
  height: fit-content;
  justify-content: stretch;
}

.flex-rownowrap {
  display:flex;
  flex-wrap: nowrap;
  height: fit-content;
  justify-content: stretch;
}

.f-between, .flex-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.flex-around {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.fb49 { flex-basis: 49%; }
.fb50 { flex-basis: 50%; }
.fb25 { flex-basis: 25%; }
.fb75 { flex-basis: 75%; }
.fb33 { flex-basis: 33%; }
.fb66 { flex-basis: 66%; }
.fb10 { flex-basis: 10%; }

.fb360 {display: flex; width: 360px} //The max width you can count on for a phone
.fbm360 {display: flex; min-width: 360px} //Won't get smaller than that'

.w49 {width: 49%;}
.w360 {width: 360px;}
.wm360,.mw360 {min-width: 360px;}

.iflex16 {display: inline-flex; flex-basis: calc(1/6)*100%; }
.iflex26 {display: inline-flex; flex-basis: calc(2/6)*100%; }
.iflex36 {display: inline-flex; flex-basis: calc(3/6)*100%; }
.iflex46 {display: inline-flex; flex-basis: calc(4/6)*100%; }
.iflex56 {display: inline-flex; flex-basis: calc(5/6)*100%; }
.iflex66 {display: inline-flex; flex-basis: calc(6/6)*100%; }

/*
.flex-between:after {
  content: "";
  flex: auto;
}
*/
/** Apply to the flex box */
.flexalignstretch {align-items: stretch;}
.flexjustifyaround {justify-content: space-around;}

/** Apply to the flex items */
.flexgrow-2, .flexgrow2 { flex-grow: 2; }
.flexgrow-1, .flexgrow1, .flex-grow, .flexgrow { flex-grow: 1; }
.no-grow, .flexgrow-0, .flexgrow0 { flex-grow: 0; }
.flex-grow { flex-grow: 5; }

.inline-flex { display: inline-flex;}


/******   Text Tweaks ***********/
.small { font-size: small ! important; }
.smaller { font-size: smaller ! important; }
.larger { font-size: large ! important; }
.large { font-size: large ! important; }
.x-large { font-size: x-large ! important; }
.xx-large { font-size: xx-large ! important; }
.table-cell { display: table-cell ! important; }
.vertical-align-bottom { vertical-align: bottom !important; }




table.no-borders td,
table.noborders td,
table.no-borders th,
table.noborders th {
  border: none;
}


$boolean-input-zoom: 2 !default;
input.boolean.viewable {
  zoom: $boolean-input-zoom;
}
/** Make a link ordinary again..*/
a.just-link {
  color: blue;
  text-decoration: underline;
  border: none;
}
a.just-link:focus,
a.just-link:hover {
  outline: none;
}
a.just-link:hover {
  color: #00c;
}


a.undecorated:hover ,
a.undecorated {
  text-decoration: none;
}


// For initially hidden help divs, pop up when help button clicked
#helpTitle, 
#helpContent {
  display: none;
}
.btn-wrp {
  padding: 0;
  text-align: center;
}

.row-flex, 
.flex-row, 
.flex-col, 
.col-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.flex-col, 
.col-flex, .flex-column {
  flex-direction: column;
}



.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

/* Throw in everything here to try to center contents */
@mixin make-flex-horizontal-centerer {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.horizontal-centerer { @include make-flex-horizontal-centerer}

.currency, .number, .numeric, .int, .integer, .jq-format-currency {
  text-align: right;
}

/** Depends on being fixed to the window */
.hover-detail-frame {
  background: #fff;
  padding: 3rem;
  border-radius: .5em;
  border: solid red 1px;
  position: fixed;
  z-index: 99999;
  margin: 0;
  left: 50%;
  //top: 50%;
  top: 25rem;
  transform:translate(-50%,-50%);
  min-width: 80%;
  min-height: 10rem;
  box-shadow: inset 0 0 20px 0 #1d2845, 0 1px 0 white;
}

.hover-title {
  font-size: larger;
  color: white;
  background-color: #334;
  width: 100%;
  font-weight: 500;
  margin: 0;
  margin-bottom: .5rem;
  padding: .5rem;
  text-align: center;
}

.array-head {
  font-size: larger;
  font-weight: 500;
  text-align: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
  
}



.faded {
  background-color: rgba(200,200,200,.5);
  
}


@media print {
  * {
    background-color: white;
  }
  .print-button {
    display: none ! important;
  }

  .no-print {
    display: none ! important;
  }
  div.template {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: white !important;
  }

  .print-black {
    color: black ! important;
    text-decoration: none ! important;
    }
    .break-after {
      page-break-after: always;
    }
    .break-before {
      page-break-before: always;
    }
}

@media screen {
  body div *.print-only, body div *.no-screen {
    display: none !important;
  }
}

.row.col-inner-border [class*="col"] {
   @extend .inner-border;
}
/** A jQuery UI Dialog Box that should pop-up on page load if it exists*/

.js-dialog-content,
.jqui-dlg-pop-load-wrapper,
.jqui-dlg-pop-load {
  display: none;
}

$pk-warn-dlg-titlebar-color: red !default;
.pk-warn-dlg .ui-dialog-titlebar {
  font-size: large;
  color: $pk-warn-dlg-titlebar-color;
  font-weight: bold;
}

$pk-warn-dlg-color: #800 !default;
.pk-warn-dlg.error-dlg-box li {
  font-size: large;
  color: $pk-warn-dlg-color;
}

$pk-warn-dlg-background: rgba(255,100,100,.4) !default;
div.error-popup-box.ui-dialog-content {
  background: $pk-warn-dlg-background;
}

.pre-wrap {
  white-space: pre-wrap;
}


/**************   HERE IS WHERE TO PUT ALL VARIED MEDIA SIZE OPTS ***********/

//Try to make useful media size classes for all breakpoints
//Using my mixins above-bp, below-bp, between-bps

@each $bp in map-keys($grid-breakpoints) {
  .d-block-below-#{$bp} {
    @include below-bp($bp) {
      display: block !important;
      //display: block;
    }
  }
  .d-block-within-below-#{$bp} {
    @include below-bp($bp) {
      flex-wrap: wrap !important;
    }
  }
  // Any divs CONTAINED in this el must be block
  .d-block-within-below-#{$bp} > div {
    @include below-bp($bp) {
      display: block !important;
      width: 100% !important;
    }
  }
  .hidden-#{$bp}-down,
  .d-none-below-#{$bp} {
    @include below-bp($bp) {
      display: none !important;
    }
  }
}


@mixin mk-offsets() {
  @for $i from 0 through 100 {
    $ex: if($i!=0, -$i, null);
    $sz: calc($i/10) *1rem;
    .top#{$ex} {
      top: $sz;
    }
    .left#{$ex} {
      left: $sz;
    }
  }
}

@include mk-offsets;

// An initial default table style for pk-tbl

$pk-tbl-border-color: #aaa !default;

table.pk-tbl,
table.pk-tbl {
  td, th {
  border-collapse: collapse;
  border: solid $pk-tbl-border-color 1px;
  padding: .5em;
  }
}
.border-bottom, .underline {
  border-bottom: solid 1px;
  border-radius: 0;
}
.sect-sep {
  border-bottom: solid 1px $pk-tbl-border-color;
  font-weight: bold;
  margin-top: .5em;
}
// Make some inline emphasis
.bb, .br {
  font-weight: bold;
  color: #004;
  display: inline-block;
}
.br {
  color: #400;
}

.emr, .emb {
  font-style: italic;
  display: inline-block;
  color: #004;
}
.emr {
  color: #400;
}

.embr {
  @extend .emr;
  @extend .br;
}

.embb {
  @extend .emb;
  @extend .bb;
}

.bw2 { border-width: 2px !important; }
.bw3 { border-width: 3px !important; }



/****  Assume some nice fonts: **/
/**

    <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700|Oswald:400,700|Lato:400,700|Roboto:400,700|Raleway:400,600,700"
*/

.oswald { font-family: 'Oswald', verdana; }
.lato { font-family: 'Lato', arial; }
.roboto { font-family: 'Roboto', verdana; }
.open-sans { font-family: 'Open Sans', verdana; }
.montserrat { font-family: 'Montserrat', verdana; }
.raleway { font-family: 'Raleway', verdana; }
.droid-sans { font-family: 'Droid Sans', verdana; }
.s-sans-pro {font-family: 'Source Sans Pro', sans-serif;}

/** Test with:
<div class='x-large oswald'>This is Oswald</div>
<div class='x-large lato'>This is Lato</div>
<div class='x-large roboto'>This is Roboto</div>
<div class='x-large open-sans'>This is Open Sans</div>
<div class='x-large montserrat'>This is Montesserat</div>
<div class='x-large raleway'>This is Raleway</div>
*/


.table { display: table !important; }
.flex { display: flex !important; }


.align-self-center {align-self: center;}

.inv-blue {
  background-color: #008;
  color: #fff;
}

.inv-red {
  background-color: #800;
  color: #fff;
}
.inv-green {
  background-color: #080;
  color: #fff;
}

.b {
  font-weight: bold;
}

hr {
  @extend .border;
}

tr {
  page-break-inside: avoid !important;
}

//Experimental - to get the CHOSEN value of a select control centered

select.select-center {
  text-align: center;
  text-align-last: center;
}

input::placeholder { color: #999 !important; }

.relative { position: relative !important; }
.absolute { position: absolute !important; }
.fixed { position: absolute !important; }

.under { z-index: -1 !important; }

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.reszable, .h-center {
  z-index: 10;
}

.vue-popup {
  position: fixed;
  top: 10%;
  @extend .h-center;
}


// Make classes that will force a break between columns at certain breakpoints-
// the regular sm, md, lg, but also pixels brk-sm, brk-md, brk-30
@mixin mk-breaks {
  $breaks: ('sm', 'md', 'lg', 'xl', 50, 100, 150, 200, 250, 300, 350, 400,
    450, 500, 550, 600, 650, 700);
  @each $break in $breaks {
    .brk-#{$break} {
      @include below-bp($break) {
        display: block;
        width: 100%;
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@include mk-breaks;

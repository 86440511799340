/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */
/** Mixins to add to BootStrap (4 alpha 6). Assumes "_variables.scss' is in the GULP path */
/*
//Optional Google Fonts
<link href="https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700|Oswald:400,700|Lato:400,700"
          rel="stylesheet">
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', verdana;
font-family: 'Oswald', verdana;
font-family: 'Lato', verdana;
*/

@use 'sass:math';

@import 'variables';
// If they weren't previously defined, use defaults
$grid-breakpoints: (xs: 0, sm: 544px, md: 768px, lg: 992px, xl: 1200px) !default;
$grid-columns:               12 !default;
$grid-gutter-width:          1.875rem !default; // 30px

//First three are to define classes at breakpoints - above, below, and between
// Just use like:
/*
.container-fluid {
  @include below-bp(sm) {
   margin: 0;
   padding: 0;
  }
}
*/

//Just a function to translate different size units
//to a standard
@function breakpoint($sz) {
  @if type-of($sz) == string {
    $sz: map-get($grid-breakpoints, $sz)
  }
  @if type-of($sz) != 'number' {
    @debug ('Result of sz:', $sz);
    @error 'Bad parameter sz:';
  }
  @return $sz;
}

//Build custom media size classes. Ex:
/*
.btn {
  margin-left: 5px;
 }

.btn {
@include below-bp(sm) {
   margin-left: 0;
  }
}
//Produces:

@media (max-width: 575px) {
  .btn {
    margin-left: 0; } }
*/
/* OR can you inlude a bunch of class declarations 
   in a media mixin & just do it once? but I guess you could do that
   anyway....


@include below-bp(200) {
  .
}
*/



// Can take a BS 'sz' ('xs', 'sm', etc)
@mixin above-bp($sz) {
  $breakpoint: breakpoint($sz);
  @media (min-width: $breakpoint) {
    @content
  }
}
@mixin below-bp($sz) {
  $breakpoint: breakpoint($sz);
  @media (max-width: $breakpoint - 1px) {
    @content
  }
}

@mixin between-bps($min, $max) {
  $min-breakpoint: breakpoint($min);
  $max-breakpoint:  breakpoint($max);
  @media ( min-width: $min-breakpoint ) and (max-width: $max-breakpoint - 1px) {
    @content
  }
}

@mixin mx-text-format($sz:medium, $weight:400, $color:black) {}



/* The CONTAINING element must be RELATIVE*/
@mixin absolutecenter($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    margin-top: 0;
    margin-bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    margin-left: 0;
    margin-right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    margin: 0;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}

/* The CONTAINING element must be RELATIVE*/
@mixin make-horizontal-center {
  @include absolutecenter(x);
}

@mixin make-vertical-center {
  @include absolutecenter(y);
}


//In SCSS, '' & 0 are true
@function empty($value) {
  @if not($value) 
      or $value == "" 
      or $value == 0
      or $value == ()
      or length($value) == 0 {
    @return true;
  }
  @return false;
}



/** Makes (multiple) text format classes, based on $args map:
 * All class names prepended by 'au-'
 * Params:
 * cname: format - class name(s) will be au-cname
 * weight: null - font-weight
 * font: null - font-family
 * szrange: 0 or int - how many class sizes
 * crange: null or true - generate lighter & darker fonts?
 * bgrange: null or 1 - generate light & dark bg? (Prefer: Use wrapper)
 * background: null - or color (Prefer: Use wrapper for BG)
 * margin: null - or margin (Prefer: Use wrapper)
 * padding: null or padding (Prefer: Use wrapper)
 * align: null - or center or left or right (Prefer: Use wrapper)
 * color: null or color
 * border: null or color
 * 
*/


@mixin mk-format($args:()) {
  $defaults: (cname:format,szrange:0, size:1em);
  $params: map-merge($defaults,$args);
  $cname: map-get($params,cname);
  $bname: au-#{$cname};
  $border: map-get($params,border);
  $szrange: map-get($params,szrange);
  $crange: map-get($params,crange);
  $bgrange: map-get($params,bgrange);
  $background: map-get($params,background);
  $color: map-get($params,color);
  $weight: map-get($params,weight);
  $display: map-get($params,display);
  $align: map-get($params,align);
  $font: map-get($params,font);
  $size: map-get($params,size);
  $textshadow: map-get($params,textshadow);
  $boxshadow: map-get($params,boxshadow);
  
  $map:();
  @if $border {
    $map: map-merge($map, (border: solid $border 1px,
      border-radius: .5em));
  }
  $map:map-merge($map,(display:$display,
      text-align:$align,
      font-family:$font));

  $clmap: if($color and $crange, 
        ('-flt':(color:lighten($color,20%)),
          '':(),
          '-fdk':(color:darken($color,20%))),
          ('':(color:$color)));


  $bgmap: if($background and $bgrange, 
        ('-bglt':(background:lighten($color,20%)),
          '':(background:$background),
          '-bgdk':(background:darken($background,20%))),
          ('':(background:$background)));

  @for $i from 0 through $szrange {
    $sz: if($i!=0, $i, null);
    // For a while was:
    //$sz: if($i!=0, -$i, null);
    $szmap: (font-size:calc($i/5)+$size);
    @each $bga in map-keys($bgmap) {
      $bmap: map-get($bgmap,$bga);
      @each $ca in map-keys($clmap) {
        $cmap: map-get($clmap,$ca);
        //@debug #{$bname}#{$ca}#{$bga}#{$sz};
        .#{$bname}#{$ca}#{$bga}#{$sz} {
          @include mk-decblock($map);
          @include mk-decblock($cmap);
          @include mk-decblock($bmap);
          @include mk-decblock($szmap);
          @include mk-boxshadow($boxshadow);
          @include mk-textshadow($textshadow);
        }
      }
    }
  }
}



// Builds a declaration block from mapped property/values

//@mixin mk-decblock($map:()) {
//  $keys: map-keys($map);
//  @each $key in $keys {
//    #{$key}: map-get($map,$key);
//  }
//}

@mixin mk-classdef($cname,$maplist) {
  .#{$cname} {
    @each $map in $maplist {
      @include mk-decblock($map);
    }
  }
}
@mixin mk-decblock($map:()) {
  $keys: map-keys($map);
  @each $key in $keys {
    @if not(empty($key) or empty(map-get($map,$key))) {
      #{$key}: map-get($map,$key);
    }
  }
}



@mixin mk-boxshadow($map:()) {
  @if not(empty($map)) {
    @if $map == true {
      box-shadow: 1rem 1rem 3rem -1rem rgba(0,0,0,.5);
    }
  }
}
@mixin mk-textshadow ($map:()) {
  @if not(empty($map)) {
    @if $map == true {
      text-shadow: .1rem .1rem .3rem  rgba(0,0,0,.5);
    }
  }

}


//@include mk-format((cname:head,
//    szrange:3,
//     color:#456,crange:1,
//     background:#cde,
//     bgrange:1,
//     border: #aaf,
//     display: inline,
//     ));

@mixin h-padding($sz) {
  padding-left: $sz;
  padding-right: $sz;
}

@mixin v-padding($sz) {
  padding-top: $sz;
  padding-bottom: $sz;
}

@mixin v-margin($sz) {
  margin-top: $sz;
  margin-bottom: $sz;
}

@mixin h-margin($sz: auto) {
  margin-left: $sz;
  margin-right: $sz;
}

@mixin mk-zooms {
  @for $i from 0 through 50 {
    $inc: calc($i/10);
    .z#{$i} { zoom: 1+$inc; }
    .z-#{$i} { zoom: 1-$inc; }
  }
}
@include mk-zooms;


@for $i from 1 through 100 {
  .width#{$i}pc {width: $i * 1%;}
  .w#{$i}pc {width: $i * 1%;}
  .height#{$i}pc {height: $i * 1%;}
  .h#{$i}pc {height: $i * 1%;}
  .h#{$i} {height: $i * 1em;}
  .w#{$i} {width: $i * 1em;}
}





/* Makes a bunch of left/right margins/paddings */
.fs-s {
  font-size: smaller;
}
.fs-l {
  font-size: larger;
}
//These are REM - so absolute across the site.
@mixin mk-spacing {
  @for $i from 0 through 90 {
    $sz: if($i!=0, -$i, 0);
    $offset: calc($i/3) * 1rem;
    $eoffset: calc($i/3) * 1em;
    $foffset: calc($offset/5);
    $feoffset: calc($eoffset/5);
    @if $i < 50 {
      //$foffset: $offset + 1em;
      //Font Size: REM, up and down - fs-0: 1rem;, fs-1: fs--1
      .fs#{$sz}, .f-s#{$sz} {
        font-size: 1rem + $foffset !important;
      }
      .fs-#{$sz}, .f-s-#{$sz} {
        font-size: 1rem - $foffset !important;
      }
    } 

    .w#{$i}em {width: #{$i}em;}
    .w#{$i}rem {width: #{$i}rem;}
    .br-#{$sz} {border-radius: $foffset !important}

    //Heights & widths
    .h#{$sz} {height: $offset !important;}
    .height#{$sz} {height: $offset !important;}
    .minh#{$sz}, .minh-#{$sz},  .min-height#{$sz} {min-height: $offset !important;}
    .maxh#{$sz}, .maxh-#{$sz},   .max-height#{$sz} {max-height: $offset !important;}
    .width#{$sz}, .w#{$sz}  {width: $offset !important;}
    
    .hw#{$sz}, .wh#{$sz} {width: $offset !important; height: $offset !important;}
    .minw#{$sz}, .minw-#{$sz}, .min-width#{$sz} {min-width: $offset !important;}
    .maxw#{$sz}, .maxw-#{$sz}, .max-width#{$sz} {max-width: $offset !important;}
    //Paddings
    //REM
    .p-r#{$sz}, .pr#{$sz}, .pad-r#{$sz} { padding-right: $offset !important; }
    .p-l#{$sz}, .pl#{$sz}, .pad-l#{$sz} { padding-left: $offset !important; }
    .p-t#{$sz}, .pt#{$sz}, .pad-t#{$sz} { padding-top: $offset !important; }
    .p-b#{$sz}, .pb#{$sz}, .pad-b#{$sz} { padding-bottom: $offset !important; }
    .p-v#{$sz}, .pv#{$sz}, .pad-v#{$sz} { padding-top: $offset !important; padding-bottom: $offset !important; }
    .p-h#{$sz}, .ph#{$sz}, .pad-h#{$sz} { padding-left: $offset !important; padding-right: $offset !important; }
    .p#{$sz}, .p#{$sz}, .pad#{$sz} { padding: $offset !important; }

    //EM
    .pe-r#{$sz}, .pade-r#{$sz} { padding-right: $eoffset !important; }
    .pe-l#{$sz}, .pade-l#{$sz} { padding-left: $eoffset !important; }
    .pe-t#{$sz}, .pade-t#{$sz} { padding-top: $eoffset !important; }
    .pe-b#{$sz}, .pade-b#{$sz} { padding-bottom: $eoffset !important; }
    .pe-v#{$sz}, .pade-v#{$sz} { padding-top: $eoffset !important; padding-bottom: $eoffset !important; }
    .pe-h#{$sz}, .pad-h#{$sz} { padding-left: $eoffset !important; padding-right: $eoffset !important; }
    .pe#{$sz}, .pad#{$sz} { padding: $eoffset !important; }

    //Margins
    //REM
    .m-r#{$sz},.mr#{$sz}, .marg-r#{$sz} { margin-right: $offset !important; }
    .m-l#{$sz},.ml#{$sz}, .marg-l#{$sz} { margin-left: $offset !important; }
    .m-t#{$sz},.mt#{$sz}, .marg-t#{$sz} { margin-top: $offset !important; }
    .m-b#{$sz},.mb#{$sz}, .marg-b#{$sz} { margin-bottom: $offset !important; }
    .m-v#{$sz},.mv#{$sz}, .marg-v#{$sz} { margin-top: $offset !important; margin-bottom: $offset !important; }
    .m-h#{$sz},.mh#{$sz}, .marg-h#{$sz} { margin-right: $offset !important; margin-left: $offset !important; }

    .m-r-#{$sz}, .mr-#{$sz}, .marg-r-#{$sz} { margin-right: -$offset !important; }
    .m-l-#{$sz}, .ml-#{$sz}, .marg-l-#{$sz} { margin-left: -$offset !important; }
    .m-t-#{$sz}, .mt-#{$sz}, .marg-t-#{$sz} { margin-top: -$offset !important; }
    .m-b-#{$sz}, .mb-#{$sz}, .marg-b-#{$sz} { margin-bottom: -$offset !important; }
    .m-v-#{$sz}, .mv-#{$sz}, .marg-v-#{$sz} { margin-top: -$offset !important; margin-bottom: -$offset !important; }
    .m-h-#{$sz}, .mh-#{$sz}, .marg-h-#{$sz} { margin-right: -$offset !important; margin-left: -$offset !important; }

    .m#{$sz}, .marg#{$sz} { margin: $offset !important; }

    //EM
    .me-r#{$sz}, .mer#{$sz}, .marge-r#{$sz} { margin-right: $eoffset !important; }
    .me-l#{$sz}, .mel#{$sz}, .marge-l#{$sz} { margin-left: $eoffset !important; }
    .me-t#{$sz}, .met#{$sz}, .marge-t#{$sz} { margin-top: $eoffset !important; }
    .me-b#{$sz}, .meb#{$sz}, .marge-b#{$sz} { margin-bottom: $eoffset !important; }
    .me-v#{$sz}, .mev#{$sz}, .marge-v#{$sz} { margin-top: $eoffset !important; margin-bottom: $offset !important; }
    .me-h#{$sz}, .meh#{$sz}, .marge-h#{$sz} { margin-right: $eoffset !important; margin-left: $eoffset !important; }

    .me-r-#{$sz}, .mer-#{$sz}, .marge-r-#{$sz} { margin-right: -$eoffset !important; }
    .me-l-#{$sz}, .mel-#{$sz}, .marge-l-#{$sz} { margin-left: -$eoffset !important; }
    .me-t-#{$sz}, .met-#{$sz}, .marge-t-#{$sz} { margin-top: -$eoffset !important; }
    .me-b-#{$sz}, .meb-#{$sz}, .marge-b-#{$sz} { margin-bottom: -$eoffset !important; }
    .me-v-#{$sz}, .mev-#{$sz}, .marge-v-#{$sz} { margin-top: -$eoffset !important; margin-bottom: -$eoffset !important; }
    .me-h-#{$sz}, .meh-#{$sz}, .marge-h-#{$sz} { margin-right: -$eoffset !important; margin-left: -$eoffset !important; }

    .me#{$sz}, .marge#{$sz} { margin: $eoffset !important; }
  }

  .ma, .m-a {margin: auto !important;}
  .mva, .m-v-a {margin-top: auto !important; margin-bottom: auto !important;}
  .mha, .m-h-a {margin-left: auto !important; margin-right: auto !important;}
  .mta, .m-t-a {margin-top: auto !important;}
  .mba, .m-b-a {margin-bottom: auto !important;}
  .mla, .m-l-a {margin-left: auto !important;}
  .mra, .m-r-a {margin-right: auto !important;}
}


//Make font weights
@mixin mk-weights {
  @for $i from 1 through 9 {
    $weight: 100 * $i;
    .fw-#{$i},
    .f-w-#{$i},
    .fw#{$i},
    .f-w#{$i} {
        font-weight: $weight !important;
      }
  }
}

@include mk-weights;


/** Makes text/section wrappers, with MAP args/defaults:
 * boxshadow: default for the mk-boxshadow mixin
 * border: the border color, or false
 * background: the background color, or false
 * align: text alignment of the contained text - left, right, center
 * v-padding: 1em
 * h-padding: null
 * v-margin: 1em
 * h-margin: null
 * border-radius: null
 * margin: null, if present, overrides v & h margin
 * padding: null, if present, overrides v & h padding
 */

@mixin mk-wrapper($args:()) {
  $defaults: (
    boxshadow:true,
    border:#aaa,
    background: rgba(230,240,250,.3),
    v-padding: 1em,
    v-margin: 1em,
    border-radius: 5px,
    );
  $params: map-merge($defaults,$args);

  $align: map-get($params,align);
  $boxshadow: map-get($params,boxshadow);
  $border: map-get($params,border);
  $border-radius: map-get($params,border-radius);
  $background: map-get($params,background);
  $padding: map-get($params,padding);
  $margin: map-get($params,margin);
  $h-padding: map-get($params,h-padding);
  $h-margin: map-get($params,h-margin);
  $v-padding: map-get($params,v-padding);
  $v-margin: map-get($params,v-margin);

  @if $border {
    border: solid $border 1px;
    border-radius: $border-radius;
  }
  @if $align {
    text-align: $align;
  }
  @if $background {
    background: $background;
  }
  @if $boxshadow {
    @include mk-boxshadow($boxshadow);
  }
  @if $padding {
    padding: $padding;
  } @else {
    @if $h-padding {
      @include h-padding($h-padding);
    }
    @if $v-padding {
      @include v-padding($v-padding);
    }
  }
  @if $margin {
    margin: $margin;
  } @else {
    @if $h-margin {
      @include h-margin($h-margin);
    }
    @if $v-margin {
      @include v-margin($v-margin);
    }
  }
}


/** Generates a range of wrapper classes, of increasing padding/margin
 */
@mixin mk-au-wraps($args:()) {
  $defaults: (cname:wrap,szrange:10, size:1em);
  $params: map-merge($defaults,$args);
  $cname: map-get($params,cname);
  $size: map-get($params,size);
  $szrange: map-get($params,szrange);
  $bname: au-#{$cname};

  @for $i from 0 through $szrange {
    $sz: if($i!=0, -$i, null);
    //$szmap: (font-size:($i/5)+$size);
    $vpadmar: ($i/5)+($size/5);
    $newparms: map-merge($params,(v-margin:$vpadmar,v-padding:$vpadmar));
    //@debug #{$bname}#{$sz};
    .#{$bname}#{$sz} {
        @include mk-wrapper($newparms);
    }
  }
}

/** Uses mk-wrapper, above, with different defaults */
@mixin mk-section($args:()) {
  $defaults: (
    border: #aaf,
    margin: 5px,
    padding: 5px,
    background:  rgba(255,255,255,.2),
    border-radius: 5px,
    );
  $params: map-merge($defaults,$args);
  @include mk-wrapper($params);
}
@mixin mk-section-nomargin($args:()) {
  $defaults: (
    margin: 0,
  );
  $params: map-merge($defaults,$args);
  @include mk-section($params);
}

.section {
  @include mk-section;
  @include below-bp(sm) {
   margin-left: 1px;
   margin-right: 1px;
  }
}

// New BS4A6 row has -15px margin by default. Compensate....
.bs4-section {
  $params: (
    padding: 20px,
    );
  @include mk-section($params);
}


.section-nomargin {
  @include mk-section-nomargin;
}

@mixin mk-colors {
  $hl: 0 2 4 6 8 a c e f;
  @each $c1 in $hl {
    @each $c2 in $hl {
      @each $c3 in $hl {
        .bg-#{$c1}#{$c2}#{$c3} {
          background-color: unquote('#')+$c1+$c2+$c3 !important;
        }
        .c-#{$c1}#{$c2}#{$c3} {
          color: unquote('#')+$c1+$c2+$c3 !important;
        }
        .bc-#{$c1}#{$c2}#{$c3} {
          //border-color: unquote('#')+$c1+$c2+$c3 !important;
          border: solid 1px  unquote('#')+$c1+$c2+$c3 !important;
        }
      }
    }
  }
}

@include mk-colors;
$base-header-padding: .3rem !default;
//$base-header-margin: .3rem !default;
$base-header-margin: 0 !default;
$base-header-font-size: 1rem !default;
$base-header-color: #400 !default;
$base-header-ib: #004 !default;
$base-header-ir: #400 !default;

// Making site headers - 
.sh, .shr, .shb, .shib, .shir {
  font-size: $base-header-font-size;
  @extend .montserrat !optional;
  //font-family: verdana;
  color: $base-header-color;
  font-weight: bold;
  border-radius: .3em;
  padding: $base-header-padding;// 20 * $base-header-padding;
  margin: $base-header-margin; 
}
.shb {
  color: $base-header-ib;
}
.shib, .shir {
  padding: $base-header-padding 0;
  text-align: center;
  color: #fff;
}

.shib {
  background-color: $base-header-ib;
}
.shir {
  background-color: $base-header-ir;
}

@mixin mk-site-headers($base-header: sh) {
  @for $i from -5 through 20 {
     $font-size: calc($i/5) + $base-header-font-size;
    .#{$base-header}#{$i},  .#{$base-header}r#{$i},.#{$base-header}b#{$i} {
      font-size: $font-size;
      @extend  .#{$base-header};
    }
    .#{$base-header}b#{$i} {
      color: $base-header-ib;
    }
    .#{$base-header}ib#{$i}, .#{$base-header}ir#{$i} {
      //font-size: $i/5 + $base-header-font-size;
      font-size: $font-size;
      @extend  .#{$base-header};
      padding: $base-header-padding 0;
      text-align: center;
      color: #fff;
      background: $base-header-ib;
    }
    .#{$base-header}ir#{$i} {
      background: $base-header-ir;
    }
  }
}

@include mk-site-headers;


/** Some button styling, also for divs, and menus? */
@mixin mk-btn($args:()) {
  $defaults: (
    font-size: 1rem,
    font-weight: bold,
    name: site-button,
    //background:  linear-gradient(to top, #759ae9, #376fe0), 
    background: #44f,
    font-family: arial,
    color: #eee,
    padding: .4em,
    margin: .4em,
    border-radius: .3em,
    );
  $params: map-merge($defaults,$args);
  $name: map-get($params,name);
  $margin: map-get($params,margin);
  $padding: map-get($params,padding);
  $color: map-get($params,color);
  $font-size: map-get($params,font-size);
  $background: map-get($params,background);
  $border-radius: map-get($params,border-radius);
  $font-weight: map-get($params,font-weight);
  $font-family: map-get($params,font-family);

  body .#{$name}:focus,
  body .#{$name}:visited,
  body .#{$name} {
    font-size: $font-size;
    font-weight: $font-weight;
    font-family: $font-family;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    background: $background;
    border-top: 1px solid #1f58cc;
    border-right: 1px solid #1b4db3;
    border-bottom: 1px solid #174299;
    border-left: 1px solid #1b4db3;
    border-radius: $border-radius;
    box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
    color: $color;
    padding: $padding ;
    margin: $margin;
    text-shadow: 0 -1px 1px #1a5ad9;
  }
  body .#{$name}:focus a,
  body .#{$name}:visited a,
  body .#{$name} a {
    color: $color;
    text-decoration: none;
  }

  body .#{$name}:hover,
  body .#{$name}:hover a {
      text-decoration: none;
      color: lighten($color, 20%);
  }
  body .#{$name}:hover {
      background: darken($background, 20%);
      cursor: pointer; 
  }

  body .#{$name}:active {
      border-top: 1px solid #1b4db3;
      border-right: 1px solid #174299;
      border-bottom: 1px solid #133780;
      border-left: 1px solid #174299;
      box-shadow: inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
  }
}

//Will make a class '.site-button', with the defaults
@include mk-btn;

@include mk-btn((name:tiny-btn, font-size: .9rem, margin: .3em, padding: .3em));

@include mk-btn((name:site-btn, font-size: 1rem, margin: .5em, padding: .5em, font-weight: bold));


/** Some button styling, also for divs, and menus? */
/*
$default-pkmvc-button: 1 !default;
@if $default-pkmvc-button == 1 {
  body .pkmvc-button:focus,
  body .pkmvc-button:visited,
  body .pkmvc-button {
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to top, #759ae9, #376fe0);
    border-top: 1px solid #1f58cc;
    border-right: 1px solid #1b4db3;
    border-bottom: 1px solid #174299;
    border-left: 1px solid #1b4db3;
    border-radius: .3rem;
    box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8);
    color: #fff;
    font: bold 1rem/1 "helvetica neue", helvetica, arial, sans-serif;
    padding: .5rem ;
    margin: .5rem;
    text-shadow: 0 -1px 1px #1a5ad9;
  }
  body .pkmvc-button:focus a,
  body .pkmvc-button:visited a,
  body .pkmvc-button a {
    color: #fff;
    text-decoration: none;
  }

  body .pkmvc-button:hover,
  body .pkmvc-button:hover a {
      text-decoration: none;
      color: #ffe;
  }
  body .pkmvc-button:hover {
      background-image: linear-gradient(to top, #557ac9, #174fc0);
      cursor: pointer; 
  }

  body .pkmvc-button:active {
      border-top: 1px solid #1b4db3;
      border-right: 1px solid #174299;
      border-bottom: 1px solid #133780;
      border-left: 1px solid #174299;
      box-shadow: inset 0 0 5px 2px #1a47a0, 0 1px 0 #eeeeee;
  }
}
*/

/******************************************************************/
/*
Standard Classes & Mixins & Vars

Site Headers:
.sh1 -> .sh20 (Increasing in size)

Spacing/Margins/Padding:
.m-x-y
.p-x-y
where:
y an int
x in t,l,b,r,h,v, or none for all (ex, .m-h-7, .m-5)

//Colors: Font/Background/Border  ex: .bg-04f
  x in  [0 2 4 6 8 a c e f];
.bg-xxx - background color - even digits, plus f
.c-xxx - font color - even digits, plus f
.bc-xxx - border color - even digits, plus f

//Font Weights:
x from 1 - 9
.fwx
.fw-x

//Font Sizing: Based on REM , up & down:
.fs-0, .f-s-0 { font-size: 1REM !important; }
.fs-1, .f-s-1 { font-size: 1.04rem !important; }
.fs--1, .f-s--1 { font-size: 0.96rem !important; }


Google Fonts:
.verdana { font-family:  verdana; }
.oswald { font-family: 'Oswald', verdana; }
.lato { font-family: 'Lato', arial; }
.roboto { font-family: 'Roboto', verdana; }
.open-sans { font-family: 'Open Sans', verdana; }
.montserrat { font-family: 'Montserrat', verdana; }
.raleway { font-family: 'Raleway', verdana; }
.droid-sans { font-family: 'Droid Sans', verdana; }

Inverse Bacgrounds:
.inv-blue { background-color: #008; color: #fff; }
.inv-red { background-color: #800; color: #fff; }
.inv-green { background-color: #080; color: #fff; }

Flex Boxes:
.flex { display: flex; }
.flex-grow { flex-grow: 5; }
.align-self-center {align-self: center;}

Tables:
$pk-tbl-border-color: #aaa !default;

table.pk-tbl, table.pk-tbl { td, th {
  border-collapse: collapse;
  border: solid $pk-tbl-border-color 1px;
  padding: .5em; } }

.pre-wrap { white-space: pre-wrap; }
.js-dialog-content { display: none; }
/ ******   Text Tweaks *********** /
.large { font-size: large ! important; }
.x-large { font-size: x-large ! important; }
.xx-large { font-size: xx-large ! important; }
.table-cell { display: table-cell ! important; }
.vertical-align-bottom { vertical-align: bottom !important; }

.no-space { margin: 0; padding: 0! important ; border: none! important ;
  background: none transparent ! important ; }
.big-box-shadow { box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75); }
.box-shadow { box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8); }
.box-shadow-2 { box-shadow: inset 0 0 2px 0 rgba(57, 140, 255, 0.8); }

.border-radius-5, .border-radius5 {border-radius: 5px ! important}
.fullheight, div .full-height { height: 100% ! important; }
.fullwidth, div .full-width { width: 100% ! important; }

.italic { font-style: italic; }
.bold { font-weight: bold ! important; }
.float-left, .floatleft { float: left ! important; }
.float-right, .floatrignt { float: right ! important; }
.block { display: block ! important; }
.inline { display: inline-block ! important; }
.hidden { display: none; }
.invisible { visibility: hidden; }

.overflowauto, .overflow-auto {overflow:auto;}
.top-square-corners { border-top-left-radius: 0px ! important; border-top-right-radius: 0px ! important; }
.top-round-corners { border-top-left-radius: 5px ! important; border-top-right-radius: 5px ! important; }
.bottom-round-corners { border-bottom-left-radius: 5px ! important; border-bottom-right-radius: 5px ! important; }
.bottom-square-corners { border-bottom-left-radius: 0px ! important; border-bottom-right-radius: 0px ! important; }

//Some autogenerated classes - too complicated to remember:
//Auto-wrap classes for borders/surronding:

.au-wrap {
.au-wrap-1 {
.au-wrap-2 {
.au-wrap-3 {
.au-wrap-4 {, etc.

Unfortunately a bunch ov over-compex generated text classes, lik
.au-head-flt-7 {
.au-head-7 {
.au-head-fdk-7 {
.au-head-flt-8 {
.au-head-8 {
.au-head-fdk-8 {
//Which all has meaning

.section { @include mk-section; }
.section-nomargin { @include mk-section-nomargin; }


// Macros & Mixins:
@function empty($value) { @if not($value) or $value == "" or $value == 0
      or $value == () or length($value) == 0 


@mixin mk-textshadow ($map:()) {
@mixin mk-boxshadow($map:()) {


Text formatting was too difficult to use, but maybe the wrap classes?





*/

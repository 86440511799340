/** Mods/add-ons to default BS 4 */
/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */

.form-control {
  height: auto;
}

/** Replacing removed elements from BS 4 Alpha -> Beta **/
$brand-inverse:  #292b2c !default;
.navbar-inverse .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
/*
*/

$input-font-size: 1em !default;

.container-fluid.content-main.pk-main {
  padding: 0;
  margin: 0;
  position: relative;
}

.pk-template-content {
  padding: 0 2em;
}
div.pk-template {
  padding: 0;
  margin: 0;
}

//To dropdown menus on hover. Has a JS part also, in jquery-ui-config
.dropdown:hover>.dropdown-menu {
  margin: 0;
  display: block;
}
//Customizing TAB inactive Background & Font color
.nav-tabs .nav-link {
  @if  variable-exists(nav-tabs-inactive-link-hover-color) {
    color: $nav-tabs-inactive-link-hover-color;
  }
  @if  variable-exists(nav-tabs-inactive-link-hover-bg) {
    background-color: $nav-tabs-inactive-link-hover-bg
  }
}

@if   variable-exists(nav-tabs-bg-color) {
  ul.nav.nav-tabs {
    background-color: $nav-tabs-bg-color ;
  }
}

$z-pk-top-menu: 10000 !default;
$z-sub-nav: 5000 !default;
$nav-link-color: #eee !default;

$site-brand-menu-img-max-width: 24px !default;
$site-brand-menu-img-max-height: 24px !default;

img.head-img.img-fluid.tpm-head-img,
img.navbar-brand-img {
  $site-brand-menu-img-margin: 0 !default;
  $site-brand-menu-img-padding: 0 !default;
  margin: $site-brand-menu-img-margin;
  padding: $site-brand-menu-img-padding;
  max-width: $site-brand-menu-img-max-width;
  max-height: $site-brand-menu-img-max-height;
}

.fixed-menu-container {
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  @include below-bp(md) {
   position: relative;
  }
}

body .navbar {
  padding: .5em 1em;
}

.zup:hover {
  z-index: 9999999 !important;
}
// Play with customizing navbars
//$nav-item-after-nav-item-margin-left: .5rem !default;
$nav-item-hpad: .25em !default;
$pk-nav-nav-link-margin-top: 5px !default;
$pk-top-menu-height: 40px !default;
$pk-nav-sub-nav-menu-height: 35px !default;
$pk-top-menu-nav-link-margin-top: 0 !default;
//$pk-top-menu-font-size: .8rem !default;
$pk-top-menu-font-size: 1rem !default;
$menus-wrapper-height: $pk-top-menu-height +  $pk-nav-sub-nav-menu-height !default;
.menus-wrapper {
  height: $menus-wrapper-height;
  border: solid blue 1px;
}
body nav.pk-nav.sub-nav {
  position: fixed;
  top: $pk-top-menu-height;
  height: $pk-nav-sub-nav-menu-height;
}
body nav.navbar.pk-top-menu {
  position: fixed;
  height: $pk-top-menu-height;
  font-size: $pk-top-menu-font-size;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $z-pk-top-menu;
  background-color: $brand-inverse;
  flex-direction: row;
 // height: $pk-top-menu-height;
  //nav-link needs the same padding as the inline login form
  .nav-link, .nav-text {
    margin-top: $pk-top-menu-nav-link-margin-top;
    padding: 0 1em;
    //color: #eee;
    color: $nav-link-color;
    @include below-bp(sm) {
      margin: 0;
  //    padding: .5em 0;
    }
    &:hover {
      color: white;
      text-shadow:0 0 1px white;
      cursor: pointer;
    }
  }
  .navbar-brand.nav-item {
    font-size: inherit;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: .8em;
  }


//@include bg-variant('.bg-inverse', $brand-inverse);
  ul.nav.navbar-nav {
    //z-index: 100000;
    background-color: $brand-inverse;
    position: relative;
    top: 0;
  }
  .nav-item {
  //font-size: $pk-top-menu-font-size;
    padding-right: $nav-item-hpad; 
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav-item + .nav-item {
    //margin-left: $nav-item-after-nav-item-margin-left;
    padding-left: $nav-item-hpad;

  }
}

/*
body nav.navbar.pk-top-menu {
  @include below-bp(md) {
   position: relative;
  }
}
*/

.droponhover.dropdown:hover .dropdown-menu,
.droponhover.dropdown .dropdown-menu:hover {
  display: block;
} 

ul.nav.navbar-nav.li {
  text-align: center;
}


//Play with imports from SBC-Exchange
.pk-top-menu ul.nav.navbar-nav > .nav-item {
  margin-top: 0;
}

nav.navbar {
  margin-bottom: 0;
}


.site-name {
  text-shadow:0 0 1px #400;
}

//  Sub-menu

  nav.navbar.pk-nav.sub-nav {
//    position: fixed;
    z-index: $z-sub-nav;
    left: 0;
    width: 100%;
//    top: $pk-top-menu-height;

//    height: $pk-top-menu-height;
    flex-direction: row;
    background: $pk-nav-sub-nav-background;
    .nav-link {
      color:  $pk-nav-sub-nav-color;
      //font-size: 1rem;
      &:hover {
        color: white;
        text-shadow:0 0 1px white;
      }
    }
    .nav-item + .nav-item {
      margin-left: 1.5rem;

    }
  } 


 body nav.navbar.pk-nav.sub-nav {
    padding-top: 0;
  }

  nav.pk-nav.sub-nav ul.nav.navbar-nav {
    padding-top: 0;
  }

  nav.pk-nav.sub-nav ul.nav.navbar-nav {
    padding-top: 0;
  }

  div.dropdown-menu a.nav.navbar.pk-nav.sub-nav .dropdown-menu {
    background: $pk-nav-sub-nav-background;
    color:  $pk-nav-sub-nav-color;
      &:hover {
        color:   $pk-nav-sub-nav-background;
      }
  }


  div.container-fluid.theme-showcase.app-tpl {
    //If we need this, use JS
    //position: absolute;
    margin: 0;
    width: 100%;
    left: 0;
    //top: 2 * $pk-top-menu-height; 
  }


//Tootik Override - add 'center' config
// Positions (From tootik scss 'right')
[data-tootik][data-tootik-conf*='center'] {
  &:before {
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
  }
  &:after {
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover,
    &:focus {
    &:before {
      transform: translate(-50%, -50%);
    }
    &:after {
      transform: translate(-50%, -50%);
    }
  }
}
//Try making multi-line default
[data-tootik]:after {
  z-index: 1999999;
  min-width: 180px;
  text-overflow: clip;
  white-space: normal;
  word-break: break-word;
  background-color: $tootik-background;
  color: $tootik-color;
  font-family: $tootik-font;
  font-weight: 500;
}

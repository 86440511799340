
/** Initialize/override any Bootstrap default variables, then import Bootstrap */
$enable-shadows:     true;
$enable-gradients:  true;
$enable-transitions: true;
$gray-dark: #222;
$enable-flex: true;
//Tab properties

/** Customize Tabs */
//BS Vars
$nav-tabs-border-color: #888;
$nav-tabs-active-link-hover-color: #222;
$nav-tabs-active-link-hover-bg: rgba(200,150,150,.6);
$nav-tabs-active-link-hover-border-color: $nav-tabs-border-color; 
$nav-tabs-link-active-bg: #ffa;

$nav-tabs-inactive-link-hover-color: #44a;
$nav-tabs-inactive-link-hover-bg: rgba(250,200,200,.6);
//$nav-tabs-bg-color: $nav-tabs-inactive-link-hover-bg;
//$nav-tabs-bg-color: rgba(100,100,200,.5);

/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */
/** Default SCSS variable values for PK Extension components.
 * Override by defining site-specific settings variables partial
 * (_sbc-variables.scss) and importing FIRST
 */

 @use 'sass:math';

$base-font-size: 1em !default;
$inner-border-color: #888 !default;
$inner-border-spread: 1px !default;
$font-family-sans-serif:  Verdana !default;
$font-family-monospace:  Consolas, "Liberation Mono", "Courier New", monospace !default;

$tootik-background: #400 !default;
$tootik-color: #fff !default;
$tootik-font: "Open Sans", verdana !default;

//This should be your box size for almost everything, so they can wrap on 
//any device. Divide into 6 units for combos
$min-width: 320px !default;
//$mw56: 5*$min-width/6;
$mw56: math.div(5*$min-width,6);
$mw46: math.div(4*$min-width,6);
$mw36: math.div(3*$min-width,6);
$mw26: math.div(2*$min-width,6);
$mw16: math.div($min-width,6);


$default-border-color: #aaa;

//// Sites need a color theme, not necessarily the same. So here we defined
//// Some site colors, which can be over-ridden individually in _site_variables

$slblue: #88f !default;
$sdblue: #44e !default;
$slred: #fee !default;

$pk-nav-sub-nav-background: #a00 !default; 

$pk-nav-sub-nav-color: #ccf !default;

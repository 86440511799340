/**Copyight (C) 2016 by Paul Kirkaas - All Rights Reserved */
/** General Styles available to all sites - with default variables
 * overridable in individual sites
 */

:root {
  font-family: verdana;
}

.pk-fixed-menu {
  z-index: 500
}

/*** Make some default label, wrapper, & input classes, & input frames/containers
*/
$inp-cont-width: 50rem !default;

.input-row {//Flex, wrap, holds many input cards, wraps
  display: flex;
  flex-wrap: wrap;
  background-color: #efefef;
}
.input-card-5, .input-card-4, .input-card-3, .input-card-2, .input-card-1 {
  padding: 2px;
  margin: 2px;
  display: inline-block;
  background-color: #ded;
}
.input-card-5 { //Holds several inputs, but is of finite size
  width: 500px;
}
.input-card-4 { width: 400px; }
.input-card-3 { width: 300px; }
.input-card-2 { width: 200px; }
.input-card-1 { width: 100px; }

.fv, .fh  { display: flex; }
.fv {flex-direction: column;}
.fv200, .fh200 { display: flex; width: 200px; }
.fv200 { flex-direction: column; }

//Wraps an input or input & label
.ajax-wrap-css {
  width: 100%;
  //padding: 2px;
  margin: 2px;
  display: flex;
  border: solid #ddd 1px;
}
  
$ajax-inp-height: 2em !default;

.ajax-select-css,
.ajax-chcbxinp-css,
.ajax-inp-css{
  color: #222;
  height: $ajax-inp-height;
  line-height: $ajax-inp-height;
  border: solid #aaa 1px;
  padding: 2px;
  display: block;
  margin: 0;
  background-color: #FAFFBD ;
  &:hover,:focus {
    border: solid #bbf 1px;
    box-shadow: 3px 2px 7px 0px rgba(0,0,255,1);
    background-color: #fff;
  }
  
}
.ajax-select-css,
.ajax-inp-css{
  width:100%;
}

.ajax-chcbxlbl-css,
.ajax-lbl-css {
  /*
  color: $sdblue;
  background-color: #fefefe;
  */
  background-color: #448;
  color: white;
  border-radius: 5px;
  text-align:center;
  padding: 2px;
}
.ajax-lbl-css {
  width: 100%;
  display: block;
}

$checkbox-width: 2rem !default;
.ajax-chcbxlbl-css {
  width: calc(100% - 3rem);
  display: inline-block;
}

.ajax-chcbxinp-css {
  display: inline-block;
  width: $checkbox-width;
}

// Search/criteria styles
$dont-care-back: #ccc !default;
$do-care-back: #fff !default;

select.search-crit.dont-care {
  background-color: $dont-care-back;
  //background-color: fuchsia;
 // background-color: green;
}
select.search-crit.do-care {
  background-color: $do-care-back;
}



//div.row.search fieldset label {
div.search fieldset label {
    //font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    color: #006;
    margin-bottom: 0;
}
div.pk-checkbox input[type='checkbox'] {
  margin-right: 1rem;
}

//div.row.search div.multiselect.form-control label {
div.search div.multiselect.form-control label {
  font-size: 1em;
  color: #400;
  margin-left: .5rem;
  text-align: left;
}

select.form-control.search-crit {
  text-align-last: center;
  display: inline-block;
  font-size: 1em;
  color: #000;
}



//div.row.search {
div.search {
  margin-top: 1.5em;
  fieldset.search-crit-val-pair {
    border: solid blue 1px;
    border-radius: .5em;
    text-align: center;

  > label {
    //font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    color: #006;
  }
  }
}

div.pk-checkbox input[type='checkbox'] {
  margin-right: 1rem;
}





$body-font-size: 14px !default;
body {
  font-size: $body-font-size;
  padding: 0;
}
$page-template-border:  solid blue 1px !default;
$page-template-padding:  5px !default;
$page-template-margin:  5px !default;
.page-template {
  //border: solid blue 1px;
  width:  100%;
  padding: $page-template-padding;
  margin: $page-template-margin;
  @include below-bp(sm) {
   margin: 1px;
   padding: 1px;
  }
}

.pk-warn-dlg.error-dlg-box {
  position: fixed;
  top: 100px;
  z-index: 10000;
}

//Confirm Action jquery dialog styles
.ui-dialog.confirm-dialog {
  font-size: x-large;
  text-align: center;
  .ui-dialog-titlebar {
    background-color: #6ff;
    color: #a00;
  }
  .confirm-msg {
    color: #a00;
    font-weight: bold;
    padding: 1rem;
  }
  .ui-dialog-buttonpane {
    display: flex;
    justify-content: stretch;
  }
  .ui-dialog-buttonset {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    button {
      @extend .pkmvc-button;
    }
  }
}

.container-fluid {
  @include below-bp(sm) {
   margin: 0;
   padding: 0;
  }
}

$page-template-title-hpad: .5em !default;
$page-template-title-vpad: .25em !default;
$page-template-title-color: #400 !default;
$page-template-title-bg: #fff !default;
$page-template-title-font-size: x-large !default;
$page-template-title-text-align: center !default;
$page-template-title-radius: .3em !default;
$page-title-font: 'Montserrat' !default;
$page-subtitle-margin-top: .5em !default;
$page-subtitle-font: 'Montserrat' !default;
.page-template .page-title {
   font-size: $page-template-title-font-size;
   padding: $page-template-title-vpad $page-template-title-hpad;
   color: $page-template-title-color;
   background: $page-template-title-bg;
   text-align: $page-template-title-text-align;
   border-radius: $page-template-title-radius;
   font-family: $page-title-font, verdana;
 }

.page-template .page-title.inverse {
   background: $page-template-title-color;
   color: $page-template-title-bg;
 }

$page-title-img-maxheight: 40px !default;
$page-title-img-maxwidth: 40px !default;
$page-title-img-margin-right: 1rem !default;
.page-template .page-title img {
  vertical-align: middle;
  max-height: $page-title-img-maxheight;
  max-width: $page-title-img-maxwidth;
 }

$page-template-subtitle-color: #004 !default;
$page-template-subtitle-bg: #fff !default;
$page-template-subtitle-font-size: large !default;
$page-template-subtitle-text-align: left !default;
$page-template-subtitle-hpad: $page-template-title-hpad !default;
$page-template-subtitle-vpad: $page-template-title-vpad !default;
$page-template-subtitle-radius: $page-template-title-radius !default;
.page-template .page-subtitle {
   font-size: $page-template-subtitle-font-size;
   padding: $page-template-subtitle-vpad $page-template-subtitle-hpad;
   color: $page-template-subtitle-color;
   background: $page-template-subtitle-bg;
   text-align: $page-template-subtitle-text-align;
   border-radius: $page-template-subtitle-radius;
   font-family: $page-subtitle-font, verdana;
   margin-top: $page-subtitle-margin-top;
}
.page-template .page-subtitle.inverse {
   background: $page-template-subtitle-color;
   color: $page-template-subtitle-bg;
}

$site-lbl-color: #fff !default;
$site-lbl-background-color: #008 !default;
$site-val-color: #422 !default;
$site-negative-value-color: #a22 !default;

.pk-lbl {
  color: $site-lbl-color;
  background: $site-lbl-background-color;
}

.pk-val {
  color: $site-val-color;
  //font-weight: bold;
}

.pk-inp-wrap {
  border: solid blue 1px;
}

div input[type=checkbox].pk-inp {
  width: auto;
}
div input[type=checkbox].pk-inp.z1 { zoom: 1.5; }
div input[type=checkbox].pk-inp.z2 { zoom: 2; }

div input[type=checkbox].search-val.form-control { width: auto; display: inline-flex;}

$pk-inp-font-size: 1em !default;
$pk-inp-color: #200 !default;
$pk-inp-font-weight: 500 !default;
$pk-inp-background: #eee !default;
$pk-inp-hover-background-color: #fff !default;
$pk-inp-disabled-color: #888 !default;
$pk-inp-disabled-background: #bbb !default;
select.pk-inp,
textarea.pk-inp,
input.pk-inp {
 // @extend .form-control; 
  padding: 2px 3px;
  //font-weight: bold;
  //font-family: 'Times New Roman', serif;
  font-family: 'Montserrat', arial;
  font-size: $pk-inp-font-size;
  //font-size: 16px;
  font-weight: $pk-inp-font-weight;
  //font-family: 'Roboto', verdana;
  color: $pk-inp-color;
  background: $pk-inp-background;
  &:hover, &:focus {
    background-color: $pk-inp-hover-background-color; 
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 220, .75); 
  }
  &:disabled:hover, &:disabled {
  color: $pk-inp-disabled-color;
  background: $pk-inp-disabled-background;
}
}

select.pk-inp,
input.pk-inp {
  display: inline-flex;
}


.v-set .pk-inp { //Vertical set/pair - 
  width: 100%;
}
.pk-pair.stacked { //Label over data - replaced by above

}
.pk-pair.inline { //Label preceeds data

  .pkp-lbl {
         }
  .pkp-data {

  }
}

div.pk-pair div.checkbox-wrap.pkp-data {
  //display: inline-block;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-grow: 0 !important;
}
body div div form div select.pk-inp {
   &:not([size]):not([multiple]) {
    height: 1.7 * $pk-inp-font-size;
   }
}

.inp-dollar {
  text-align: right;
}
/*
.inp-dollar::before {
  content: '$';
}
*/

.pk-dollar-value {
  display: inline-block;
  text-align: right;
}

.pk-dollar-value.negative-dollar-value,
.pk-val.pk-dollar-value.negative-dollar-value {
  color: $site-negative-value-color;
}


/** For labels under images */
.image-desc {
  font-size: .9rem;
  border-radius: .5em;
  font-weight: 600;
  color: #422;
  text-align: center;
  margin-top: .5em;
  background-color: rgba(100,100,255,.5);

}

.row.pk-lbl [class*="col"] {
   @extend .pk-lbl;
}

.row.pk-val [class*="col"] {
   @extend .pk-val;
}

//A link within a section of text
//$a-in-text-color: #00a !default;
$a-in-text-color: inherit !default;
a.in-text {
  color: $a-in-text-color;
  text-decoration: none;
  text-shadow: .1em .1em .1em  rgba(0,0,0,.3);
  &:hover {
    //color: lighten($a-in-text-color, 50%);
   text-shadow: .1em .1em .3em  rgba(0,0,0,.5);
  }

}

// A heavier leading line
$headline-margin-bottom: .5em !default;
.head-line {
  display: block;
 // margin-bottom: $headline-margin-bottom;
  font-weight: bolder;
}

.page-template p {
  margin-bottom: .5em;
}

//Used in spans to emphasize the site name in text or title
.site-name-em {
  //font-weight: bolder;
  text-shadow: .1rem .1rem .1rem  rgba(0,0,0,.5);
  font-style: italic;
}

/** Basis for avatar handling, also pup up ajax dialogs */
//$img-avatar-max-width: 15rem !default;
$img-avatar-max-width: auto !default;
$img-avatar-max-height: 12rem !default;
$img-avatar-tiny-max-height: 32px !default;
$img-avatar-micro-max-height: 16px !default;

img.avatar {
  //max-width: $img-avatar-max-width;
  max-height: $img-avatar-max-height;
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: baseline;
}

img.avatar.small {
  max-height: calc($img-avatar-max-height/2);
}
img.avatar.xsmall {
  max-height: calc($img-avatar-max-height/3);
}
img.avatar.tiny {
  max-height: $img-avatar-tiny-max-height;
  max-width: $img-avatar-tiny-max-height;
}

img.avatar.micro {
  max-height: $img-avatar-micro-max-height;
  max-width: $img-avatar-micro-max-height;
}

.min-width, .mw66  {width: $min-width;}
.mw56 {width: $mw56;}
.mw46 { width: $mw46;}
.mw36 { width: $mw36;}
.mw26 { width: $mw26;}
.mw16 { width: $mw16;}


.basic-box, .bb {
  @extend .mw66;
}

.bb-fc {
  @extend .mw66;
  //height: fit-content;
}

.bbborder {
  @extend .mw66;
  @extend .border-rad;
  flex-grow: 1;
}

.bbfcborder {
  flex-grow: 1;
  @extend .bb-fc;
  @extend .border-rad;
}

.fcol {
  display: flex;
  flex-direction: column;
}




.avatar-frame {
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
  width: $img-avatar-max-width;
  height:  $img-avatar-max-height;
  border: solid #888 1px;
  text-align: center;
  position: relative;
  background-color: black;
}





$avatar-desc-width: $img-avatar-max-width !default;
$avatar-desc-padding: .5rem !default;
$avatar-desc-margin: .5rem !default;
$avatar-desc-bg: #eee !default;
.avatar-desc {
  width: $avatar-desc-width;
  padding: $avatar-desc-padding;
  border: 1px;
  margin: $avatar-desc-margin;
  display: inline-block;
  background: $avatar-desc-bg;
}


.new-msg-cnt {
  font-weight: 600;
  color: #f88;
}

.num-items, .item-desc {
  display: inline-block;
}

.template-heading {
  font-size: large;
  text-align: center;
  color: #33a;
  margin-bottom: .5em;
}

$template-background-color: #eef !default;
.template {
  background-color: $template-background-color;
}

div.msg.row.from.first-view  {
  border: solid red 1px;
}
div.row.first-view .msg-content {
  margin-top: 2em;
}
div.row.first-view .msg-content::before {
    position: relative;
    text-align: center;
    top: -3em;
    //content: "New";
    content: "Unread";
    font-weight: bold;
    color: red;
    display: inline-block;
    width: 100%;
}

div.msg.row {
  border: solid #aaa 1px;
  background-color: rgba(200, 200, 255, .6);
  border-radius: .5em;
  margin-top: .5em;
  margin-bottom: .5em;
  padding-bottom: .5em;
}


.msg-content {
    background-color: rgba(255, 255, 220, 0.6);
    margin-top: 1em;
    padding: 1em;
    min-height: 6em;
    position: relative;
}

//The base 'site header' - combine with other classes to make custom headers
/*
.sh {
  border-radius: .5em;
  font-weight: 600;
  padding: .3em;
  margin: .3em;
}
*/

/*
.sh1 { @extend .fs-1; @extend .sh; } 
.sh2 { @extend .fs-2; @extend .sh; } 
.sh3 { @extend .fs-3; @extend .sh; } 
.sh4 { @extend .fs-4; @extend .sh; } 
.sh5 { @extend .fs-5; @extend .sh; } 
.sh6 { @extend .fs-6; @extend .sh; } 
.sh7 { @extend .fs-7; @extend .sh; } 
.sh8 { @extend .fs-8; @extend .sh; } 
.sh9 { @extend .fs-9; @extend .sh; } 
.sh10 { @extend .fs-10; @extend .sh; } 
*/


.inverse-060 {
  color: white;
  background-color: #060;
}


.inverse-006 {
  color: white;
  background-color: #006;
}


.inverse-600 {
  color: white;
  background-color: #600;
}
.btn:hover {
  cursor: pointer;
}
img.js-big-picture-button {
  cursor: pointer;
}

img.big-picture-img {
  max-width: 500px;
}
/*
img.js-big-picture-button:hover {
  cursor: pointer;
}
*/

////////////////  Vue Pop-up Classes /////////////

.contact-body-wrapper {
  border: solid blue 1px;
  padding: .3rem;
  border-radius: .5rem;
  min-width: 35rem;
  min-height:15rem;
  background-color: #ccc;
  
  
}
.contact-header {
  color: white;
  background-color: blue;
  font-weight: bold;
  font-size: 1.2rem;
  padding: .3rem;
  text-align: center;
  margin: 0;
}
.contact-sub-wrap {
  display: block;
  padding: .3px;
  background-color: white;
  
}

.contact-ta {
  display: block;
  background-color: white;
  height: 100%;
}
.contact-ta-wrap {
  margin:  3px;
  height: 100%;
  width: 100%;
  padding: 3px;
  background-color: white;
}
.button-row {
  margin-top: .3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.popup-btn {} 

//////   Some background colors & box sized in em that can be nice labels/containers
//Just add a font-size class to admust them. Also some gradients & box-shadows
.sbk {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: .5em;
  padding: .5em;
  border-radius: .5em;
  color: white;
  font-family: verdana;
  font-weight: bold;
}

.bC30 { background-color: #C30; }
.b193542 { background-color: #193542; }
.b004BA8 { background-color: #004BA8; }
.bB94E3C { background-color: #B94E3C; }
.b772B14 { background-color: #772B14; }
.b0A4082 { background-color: #0A4082; }
.b9B59B6 { background-color: #9B59B6; }
.b1C90F3 { background-color: #1C90F3; }
.bF4307C { background-color: #F4307C; }
.b3B5998 { background-color: #3B5998; }
.b283E4A { background-color: #283E4A; }
.b0092BD { background-color: #0092BD; }
.gr1 { border: solid black 2px;}

.bs1 {box-shadow: 2px 2px 1px 1px rgba(0,0,0,.5) }
.bs2 {box-shadow: 3px 3px 2px 2px rgba(0,0,200,.5) }

.gr2 { background: linear-gradient( #02aab0, #00cdac); }
.gr3 { background: linear-gradient( to bottom, #0fb8ad 0%, #1fc8db 51%, #2cb5e8 75%); }
.gr4 { background: linear-gradient( #0fb8ad, #1fc8db); }

//Gotta get some standard label/input/value templates here...
//Start w. .pk-pair as wrapper - contains label & either input or val field
//Where pk-inp & pk-val are subclasses of pk-data
//Side by side or label over input/val
//Label can be fixed width, input/val can grow or be fixed

//Defaults over-ridden in individual site SCSS's
$pkp-pair-border: #aaa !default;
$pkp-pair-border-radius: 0 !default;
$pkp-pair-pad: .1em .1em !default;
$pkp-pair-bg: transparent !default;
$pkp-pair-mg: .1em .1em !default;
$pkp-lbl-color: #008 !default;
$pkp-lbl-wght: 400 !default;
$pkp-lbl-pad: .05em .1em !default;
$pkp-lbl-mg: .05em .1em !default;
$pkp-data-color: #404 !default;
$pkp-data-bg: #fff !default;
$pkp-data-pad: .05em .1em !default;
$pkp-data-mg: .05em .05em !default;
.pk-pair-set { //Group of pk-pairs w. similar appearance
  display: flex;
}
@for $i from 1 through 60 { //Fixed width of label or pair in a set or pair
  .pk-pair-set.sw#{$i}  {width: $i * 1em;}
  .pk-pair-set.lw#{$i} .pkp-lbl {width: $i * 1em;}
  .pk-pair.sw#{$i}  {width: $i * 1em;}
  .pk-pair.lw#{$i} .pkp-lbl {width: $i * 1em;}
  .w#{$i}em  {width: $i * 1em;}
  .w#{$i}rem  {width: $i * 1rem;}
  .mnw#{$i}em  {min-width: $i * 1em;}
  .mnw#{$i}rem  {min-width: $i * 1rem;}
  .mxw#{$i}em  {max-width: $i * 1em;}
  .mxw#{$i}rem  {max-width: $i * 1rem;}
  .h#{$i}em  {height: $i * 1em;}
  .h#{$i}rem  {height: $i * 1rem;}
  .mnh#{$i}em  {min-height: $i * 1em;}
  .mnh#{$i}rem  {min-height: $i * 1rem;}
  .mxh#{$i}em  {max-height: $i * 1em;}
  .mxh#{$i}rem  {max-height: $i * 1rem;}
}
//Example: 
//      <data-label-pair
//        :params="{name:'email',label:'Email:',
//        instance:instance, pair_wrap:'lw20'}">
//      </data-label-pair>
// - class="pk-pair lw20" makes the label 20em
.pk-pair {
  border: $pkp-pair-border;
  border-radius: $pkp-pair-border-radius;
  padding: $pkp-pair-pad;
  margin: $pkp-pair-mg;
  background: $pkp-pair-bg;
  display: flex;
    .pkp-lbl {
      color: $pkp-lbl-color;
      font-weight: $pkp-lbl-wght;
      padding: $pkp-lbl-pad;
      margin: $pkp-lbl-mg;
    }
    .pkp-data {
      color: $pkp-data-color;
      background: $pkp-data-bg;
      padding: $pkp-data-pad;
      margin: $pkp-data-mg;
      flex-grow: 1;
    }
  }




